import React from 'react'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import './videoIntro.css'
import {Langage} from "../../language";
import {appLanguage, titleNormalized} from "../../select";

const getText = (key) => {
    return Langage[key][appLanguage]
}

const VideoIntro = (props) => {

    return (
        <>
            <Button onClick={()=> {const video =  document.getElementById('vid'); video.style.display = 'block'; video.play(); props.actionProvider.next(0) }}
                    variant="contained" className={'btn-black'} endIcon={<DoneIcon />}>
                {getText('i_accept')}
            </Button>
            
            <video style={{display: "none", margin:"auto"}} playsInline id="vid">
                <source src={`/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </>
    )
}

export default VideoIntro
